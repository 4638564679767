.customer{
	&__box{
		border: 2px solid rgba($color-secondary, 0.3);
		padding: 1rem;
		border-radius: 1rem;
		margin-top: 15px;
		&--heading{
			font-family: $font-heading;

		}

		&--content{
			text-align: justify;
			// color: ;
			font-family: $font-body !important;
			p,span,a,h1,h2,h3,h4,h5,h6{
				color: unset !important;
				background: unset !important;
				background-color: unset !important;
				font-size: 1rem !important;
				font-family: $font-body !important;
				width: unset !important;
				line-height: unset !important;
				text-align: unset;
			}
		}
	}

	&__detail{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 15px;
		&--image{
			width: 3.44rem;
			height: 3.44rem;
			border-radius: 50%;
			overflow: hidden;
			margin-right: 15px;
			img{
				display: inline-block;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: cover;
			}
		}

		&--name{
			font-family: $font-heading;
			font-weight: 600;
			font-size: 0.875rem;
			margin-right: 15px;
		}
	}

	&__review{
		&-form{
			label{
				margin-bottom: 0;
			}

			textarea{
				height: 200px;
			}
		}
	}
}