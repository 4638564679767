.top__sellers {
  // min-height: 0;
  &-grid {
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(264px, 1fr));
    // grid-template-columns: repeat(auto-fit, minmax(249px, 1fr));
    // grid-template-columns: repeat(4, 22%);
    // grid-template-rows: max-content;
    // grid-gap: 4%;
    @include respond(tab-portrait) {
      // grid-template-columns: repeat(2, 48%);
      // grid-gap: 4%;
    }

    .ratings {
      // margin-top: 8px;
    }
  }

  &-card {
    margin-bottom: 36px;
    width: 100%;
    // height: 332px;
    // overflow: hidden; this causes shadow to be hidden
    position: relative;

    &-details {
      margin-top: 18px;
      &-offer {
        font-family: $font-body;
        letter-spacing: 1.2px;
        text-align: center;
        font-weight: 600;
        // margin-top: 5px;
      }

      &-price {
        font-weight: 600;
        // margin-top: 5px;
      }

      &-previous {
        &:after {
          content: "-";
          display: inline-block;
          padding: 0 0.325rem;
        }
      }
    }

    &-container {
      border-radius: 5px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-back {
      padding-top: 133.12%;
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 5px;

      &-offer {
        position: absolute;
        top: 50%;
        left: 50%;
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s;
        display: flex;
        flex-direction: column;
        width: 60%;
        align-items: center;
        background-color: transparent;
        /*border: 2px solid #fff;*/
        transform: translate(-50%, -50%) scale(0.5);
        backdrop-filter: blur(5px) brightness(70%);
        color: #fff;
        &-top {
          padding: 10px;
          font-family: Poppins !important;
          letter-spacing: 1.2px;
          text-align: center;
        }

        &-bottom {
          width: 100%;
          display: flex;
          justify-content: space-around;
          padding: 5px;
          font-family: Poppins !important;
          .previous-price {
            // text-decoration: line-through;
            position: relative;
            &:after {
              position: absolute;
              content: "";
              width: 100%;
              top: 50%;
              transform: translateY(-50%);
              left: 0;
              height: 2px;
              background-color: #000;
            }
          }
        }

        &-price {
          text-decoration: line-through;
        }
      }
      &-stock {
        background-color: #262729;
        position: absolute;
        color: #fff;
        width: 50%;
        padding: 10px;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        border-radius: 2px;
        transition: 0.5s;
        font-family: Poppins !important;
        font-weight: 600 !important;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1.2px;
      }

      &-cart {
        opacity: 0;
        visibility: hidden;
        background-color: #262729;
        position: absolute;
        color: #fff;
        width: 50%;
        padding: 10px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        border-radius: 2px;
        transition: 0.5s;
        font-family: Poppins !important;
        font-weight: 600 !important;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1.2px;
        a {
          color: #fff;
          text-decoration: none;
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
      &-front {
        transition: all 0.5s;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 5px;
        overflow: hidden;
      }

      &-link {
        position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        box-shadow: 1px 1px 5px rgba(#000, 0.2);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border-radius: 5px;
      }

      &:hover &-cart {
        visibility: visible;
        opacity: 1;
        bottom: 10px;
      }

      &:hover &-offer {
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, -50%) scale(1);
      }
    }

    &:hover &-back-front {
      visibility: hidden;
      opacity: 0;
    }

    // &-front{
    // 	transition: all 0.5s;
    // 	height: 100%;
    // 	width: 100%;
    // 	position: absolute;
    // 	top: 0;
    // 	left: 0;
    // }

    &:hover &-front {
      // opacity: 0;
      // visibility: hidden;
    }

    &:hover &-back {
      // padding-top: 133.7%;
      // opacity: 1;
      // visibility: visible;
    }
  }

  &-text {
    font-size: 14.4px;
    font-weight: 600;
    letter-spacing: 1.2px;
    position: relative;
    font-family: $font-heading;
    // &:after{
    //     content: '';
    //     position: absolute;
    //     bottom: -1px;
    //     height: 1px;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     width: 50%;
    //     background-color: #000;
    // }
  }

  &-price {
    font-size: 14px;
    font-weight: 600;
    position: relative;
    &:before {
      content: "\002D";
      display: inline-block;
      padding: 0 0.325em;
    }

    &-previous {
      // text-decoration: line-through;
      position: relative;
      &:after {
        position: absolute;
        content: "";
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: 2px;
        background-color: #000;
      }
    }
  }
}

// .sahana-offer {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   // visibility: hidden;
//   // opacity: 0;
//   transition: all 0.5s;
//   display: flex;
//   flex-direction: column;
//   width: 60%;
//   align-items: center;
//   background-color: transparent;
//   /*border: 2px solid #fff;*/
//   transform: translate(-50%, -50%) scale(0.5);
//   backdrop-filter: blur(5px) brightness(70%); }

// .sahana-offer-top {
//   padding-top: 5px;
//   font-family: Poppins !important;
//   letter-spacing: 1.2px; }

// .sahana-offer-bottom {
//   width: 100%;
//   display: flex;
//   justify-content: space-around;
//   padding: 5px;
//   font-family: Poppins !important; }

// .sahana-offer-bottom .sahana-offer-price {
//   text-decoration: line-through; }

// .t-entry-visual:hover .sahana-offer {
//   opacity: 1;
//   visibility: visible;
//   transform: translate(-50%, -50%) scale(1); }
