.sahana-checkout-form{
	label{
		font-weight: 600;
		margin-bottom: 0;
	}
}

.sahana-form-group{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	label{
		margin-bottom: 0;
	}
	>div{
		flex: 0 1 50%;
		&:not(:last-child){
			margin-right: 5%;
		}
	}
}

.sahana-account{
	span{
		width: max-content;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		input{
			margin-right: 10px;
		}
	}
}

.sahana-account-panel,
.different-accordion-panel{
	display: none;
	input{
		margin-bottom: 0.5rem;
	}
}

.sahana-different-address{
	font-style: $font-body !important;
	font-weight: 900;
	text-transform: uppercase;
	font-size: 20px;
	color: $font-color;
	display: inline-block;
	position: relative;
	&:after{
		position: absolute;
	    content: '';
	    bottom: -5px;
	    left: 50%;
	    transform: translateX(-50%);
	    width: 50%;
	    border-top: 2px solid $font-color;
	}
}

.sahana-cart{
	&-image{
		width: 50px;
		height: 40px;
		overflow: hidden;
		display: inline-block;
		img{
			display: inline-block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
}

.different-billing-trigger,
.different-shipping-trigger{
	width: max-content;
	input{
		margin-right: 5px;
	}
}

.different-billing-panel,
.different-shipping-panel{
	display: none;
}



