.preloader{
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 999999;
	background-color: #fff;
	.status{
		background-image: url(/frontend/img/preloader.gif);
		background-repeat: no-repeat;
		width: 64px;
		height: 64px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}