.footers{
	&__menu{
		@include respond(phone-landscape){
			text-align: center;
		}
		a{
			font-weight: 600;
			text-decoration: none;
			color: #fff;
			text-transform: initial;
			position: relative;
			padding-left: 22px;
			display: inline-block;
			letter-spacing: 1.2px;
			&:before{
				position: absolute;
				content: '';
				display: inline-block;
				height: 1rem;
				width: 1rem;
				top: 2.6px;
				left: 0;
				background-color: #fff;
				-webkit-mask-image: url(/frontend/img/SVG/circle-right2.svg);
				-webkit-mask-size: cover;
				mask-image: url(/frontend/img/SVG/arrow-right2.svg);
				mask-size: cover;
			}
		}
	}
	&__contact{
		&--title{
			text-align: left;
			@include respond(phone-landscape){
				text-align: center;
			}
			&--span{
			text-transform: uppercase;
			font-weight: 900;
			position: relative;
			word-spacing: 5px;
			font-family: $font-heading;
			&:after{
				content: '';
				position: absolute;
				bottom: -2px;
				width: 90%;
				left: 0;
				height: 2px;
				background-color: #fff;
			}
			}
		}

		p{
			@include respond(phone-landscape){
				text-align: center;
			}
		}
	}
	&__p{
		position: relative;
		padding-left: 22px;
		text-transform: initial;
		font-weight: 600;
		&:before{
			position: absolute;
			content: '';
			display: inline-block;
			height: 1rem;
			width: 1rem;
			top: 1px;
			left: 0px;
			background-color: #fff;
			margin-right: 8px;
			text-align: left;
		}
	}

	&__house{
		&:before{
			-webkit-mask-image: url(/frontend/img/SVG/home.svg);
			-webkit-mask-size: cover;
			mask-image: url(/frontend/img/SVG/home.svg);
			mask-size: cover;
		}
	}
	&__address{
		&:before{
			-webkit-mask-image: url(/frontend/img/SVG/location.svg);
			-webkit-mask-size: cover;
			mask-image: url(/frontend/img/SVG/location.svg);
			mask-size: cover;
		}
	}
	&__number{
		&:before{
			-webkit-mask-image: url(/frontend/img/SVG/phone.svg);
			-webkit-mask-size: cover;
			mask-image: url(/frontend/img/SVG/phone.svg);
			mask-size: cover;
		}
	}
	&__email{
		&:before{
			-webkit-mask-image: url(/frontend/img/SVG/envelop.svg);
			-webkit-mask-size: cover;
			mask-image: url(/frontend/img/SVG/envelop.svg);
			mask-size: cover;
		}
	}
	position: relative;
	&__locations{
		display: flex;
		justify-content: space-around;
		text-transform: uppercase;
		background-color: $footer-top;
		padding: 72px 0;
		color: #fff;
		font-size: 14px;
		font-weight: 900;
		@include respond(phone){
			flex-direction: column;
			align-items: center;
		}
		span{
			letter-spacing: 1.2px;
			// word-spacing: 5px;
			text-align: center;

			&:not(:last-child){
				@include respond(phone){
					margin-bottom: 15px;
				}
			}
		}
	}

	&__social{
		&--svg{
			width: 20px;
			height: 20px;
			fill: #fff;
			transition: all 0.5s;
			&--facebook{
				&:hover{
					fill: #3b5999;
				}
			}
			&--insta{
				&:hover{
					fill: #e4405f;
				}
			}
			&--youtube{
				&:hover{
					fill: #cd201f;
				}
			}
		}
		display: flex;
		justify-content: center;
		background-color: $footer-bottom;
		padding: 20px 0;
		a{
			color: #fff;
			text-decoration: none;
			&:hover{
				color: #fff;
			}
			&:not(:last-child){
				margin-right: 20px;
			}
		}
	}

	&__scrollTop{
		position: fixed;
		bottom: 15px;
		right: 15px;
		width: 40px;
		height: 40px;
        display: inline-block;
        opacity: 0;
        visibility: hidden;
        z-index: 999;
		background-color: $color-scroll;
		background-image: url(/frontend/img/upArrow.png);
		background-repeat: no-repeat;
		background-position: center;
        border-radius: 2px;
        transition: all 0.5s;
        &-visible{
            opacity: 1;
            visibility: visible;
        }
	}

	&__iframe{
		iframe{
			width: 100%;
		}
	}

	// &__contact{
	// 	@include respond(tab-portrait){
	// 		text-align: center;
	// 	}
	// }
}
