.contact{

	&__contact{
		@include respond(phone-landscape){
			margin-top: 15px;
		}
	}
	&__p{
		position: relative;
		padding-left: 22px;
		text-transform: initial;
		font-weight: 600;
		&:before{
			position: absolute;
			content: '';
			display: inline-block;
			height: 1rem;
			width: 1rem;
			top: 1px;
			left: 0px;
			background-color: #000;
			margin-right: 8px;
			text-align: left;
		}
	}

	&__house{
		&:before{
			-webkit-mask-image: url(/frontend/img/SVG/home.svg);
			-webkit-mask-size: cover;
			mask-image: url(/frontend/img/SVG/home.svg);
			mask-size: cover;
		}	
	}
	&__address{
		&:before{
			-webkit-mask-image: url(/frontend/img/SVG/location.svg);
			-webkit-mask-size: cover;
			mask-image: url(/frontend/img/SVG/location.svg);
			mask-size: cover;
		}
	}
	&__number{
		&:before{
			-webkit-mask-image: url(/frontend/img/SVG/phone.svg);
			-webkit-mask-size: cover;
			mask-image: url(/frontend/img/SVG/phone.svg);
			mask-size: cover;
		}
	}
	&__email{
		&:before{
			-webkit-mask-image: url(/frontend/img/SVG/envelop.svg);
			-webkit-mask-size: cover;
			mask-image: url(/frontend/img/SVG/envelop.svg);
			mask-size: cover;
		}
	}
}