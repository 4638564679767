header{
	position: relative;
	// &:after{
	// 	content: '';
	// 	position: absolute;
	// 	width: 100%;
	// 	height: 100%;
	// 	top: 0;
	// 	left: 0;
	// 	background-color: rgba(0,0,0,0.2);
	// 	z-index: 2;
	// }
}

.hero__carousel {
	.owl-nav{
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		margin-top: 0 !important;
		button{
			position: absolute;
			top: 0;
			// display: inline-block;
			width: 2.5rem;
			height: 2.5rem;
			transform: translateY(-50%);
			@include respond(tab-landscape){
				width: 1.5rem;
				height: 1.5rem;
			}
			@include respond(phone){
				width: 1rem;
				height: 1rem;
			}
			// background-color: $color-secondary !important;
			display: flex;
			&:hover img{
				opacity: 1;
			}
			img{
				width: 100%;
				height: auto;
				opacity: 0.6;
				transition: all 0.5s;
			}
			span{
				font-size: 40px;
				color: #000;
				display: none;
			}
			&:hover{
				background: transparent !important;
			}

			&:focus{
				outline: none !important;
			}
		}

		.owl-prev{
			left: 0px;
		}

		.owl-next{
			right: 0px;
		}
	}

	&--heading{
		color: #000;
		font-family: $font-heading;
		font-style: italic;
		font-size: 40px;
		letter-spacing: 2px;
		word-spacing: 5px;
		margin-bottom: 45px;
	}

	&--img{
		padding-top: 40.77%;
		width: 100%;
		// height: 100%;
		overflow: hidden;
		position: relative;

		&--heading{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
		}
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			position: absolute;
			top: 0;
			left: 0;
			// animation: zoom-in 5s linear;
			// animation-fill-mode: forwards;
		}
	}
}

.zoom-test{
	width: 100px;
	height: 100px;
	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}