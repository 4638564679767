.button:link,
.button:visited{
	padding: 1.125rem 2.5rem;
	border: 1px solid $color-secondary;
	color: $color-secondary;
	background-color: transparent;
	border-radius: 2px;
	text-decoration: none;
	transition: all 0.5s;
	text-transform: uppercase;
	font-size: 14px;
	display: inline-block;
	&:hover{
		background-color: $color-secondary;
		color: #fff;
	}
}

.button.pd-tp-10{
	padding: 10px 40px;
}

.button-carousel:link,
.button-carousel:visited{
	padding: 1.125rem 2.5rem;
	border: 1px solid #000;
	color:#fff;
	background-color: #000;
	border-radius: 2px;
	text-decoration: none;
	transition: all 0.5s;
	text-transform: uppercase;
	font-size: 14px;
	display: inline-block;
	font-weight: 600;
	&:hover{
		background-color: #fff;
		color: #000;
		border: none;
	}
}

input[type=submit],
.cart-button{
	font-size: 14px;
	font-family: $font-heading;
	padding: 10px 40px;
	border: 1px solid $color-secondary;
	background-color: transparent;
	border-radius: 2px;
	color: $color-secondary;
	transition: all 0.5s;
	cursor: pointer;
	&:hover{
		background-color: $color-secondary;
		color: #fff;
		text-decoration: none;
	}


}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number]{
	-moz-appearance: textfield;
}

input[type=number]:focus{
	outline: none;
}

