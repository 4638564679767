@mixin clearfix{
	::after{
		content: '';
		display: table;
		clear: both;
	}
}

@mixin respond($break-point){

	@if($break-point == phone){
		@media (max-width: 576px){
			@content;
		}
	}

	@if($break-point == phone-landscape){
		@media (max-width: 768px){
			@content;
		}
	}

	@if($break-point == tab-portrait){
		@media (max-width: 900px){
			@content;
		}
	}
	@if($break-point == tab-middle){
		@media (max-width: 968px){
			@content;
		}
	}
	@if($break-point == tab-semi){
		@media (max-width: 1100px){
			@content;
		}
	}

	@if($break-point == tab-landscape){
		@media (max-width: 1200px){
			@content;
		}
	}

	@if($break-point == big-desktop){
		@media (min-width: 1800px){
			@content;
		}
	}		
}