.gallery{
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 5px;
	&__item{
		overflow: hidden;
		width: 100%;
		&-container{
			padding-top: 150.74%;
			// padding-top: 56.25%;
			position: relative;
		}
		img{
			// position: absolute;
			// top: 0;
			// left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		&-link{
			position: absolute;
			display: inline-block;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}
}