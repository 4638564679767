.sahanabar {
	background-color: rgba(255, 255, 255, 0.9);
	position: relative;
	z-index: 3;
	border-bottom: 1px solid $nav-border-black;
	display: flex;
	align-items: center;
	height: 105px;
	backdrop-filter: blur(2px);
	transition: all 0.5s;

	@include respond(tab-portrait) {
		opacity: 0;
		visibility: hidden;
		display: none;
	}

	&.sticky-sahana {
		position: fixed;
		width: 100%;
		transition: all 0.5s;
	}

	&--mega {
		position: relative;

		&:hover .sahanabar--mega-ul {
			opacity: 1;
			visibility: visible;
			left: 100%;
		}

		&-sub {
			position: relative;

			&:hover .sahanabar--mega-sub_ul {
				opacity: 1;
				visibility: visible;
				left: 100%;
			}
		}

		&-ul {
			opacity: 0;
			visibility: hidden;
			position: absolute;
			top: 0;
			left: 120%;
			width: 200px;
			z-index: 4;
			transition: all 0.5s;
		}

		&-sub_ul {
			opacity: 0;
			visibility: hidden;
			position: absolute;
			top: 0;
			left: 120%;
			width: 200px;
			z-index: 4;
			transition: all 0.5s;
		}

		&-list {
			list-style: none;

			&:not(:last-child) {
				border-bottom: 1px solid #fff;
			}

			// border-left: 1px solid #fff;
		}

		&-link {
			color: #fff;
			background-color: #000;
			display: inline-block;
			width: 100%;
			height: 100%;
			padding: 17px 0 17px 30px;
			transition: all 0.5s;
			text-decoration: none;
			font-family: $font-heading;

			&:hover {
				color: #000;
				background-color: #fff;
				text-decoration: none;
			}
		}
	}

	&--dropdown {
		position: relative;

		&:hover {
			.sahanabar--dropdown-ul {
				opacity: 1;
				visibility: visible;
				top: 100%;
			}
		}

		&-ul {
			opacity: 0;
			visibility: hidden;
			position: absolute;
			top: 120%;
			left: 0;
			width: 200px;
			z-index: 4;
			transition: all 0.5s;
		}

		&-list {
			list-style: none;
			display: flex;
			align-items: center;

			&:not(:last-child) {
				border-bottom: 1px solid #fff;
			}

			&:hover {
				// border-bottom: 1px solid #000;
			}

			&:hover .sahanabar--dropdown-link {
				color: #000;
				background-color: #fff;
				text-decoration: none;
			}
		}

		&-link {
			color: #fff;
			background-color: #000;
			display: inline-block;
			width: 100%;
			height: 100%;
			padding: 17px 0 17px 30px;
			transition: all 0.5s;
			text-decoration: none;
			font-family: $font-heading;
		}
	}

	&--ul {
		margin-bottom: 0;
		height: 100%;
		display: flex;
		align-items: center;
	}

	&--list {
		list-style: none;

		&:not(:first-child) {
			margin-left: 30px;
		}

		// height: 105px;
		// line-height: 105px;
		display: flex;
		align-items: center;
		height: 100%;

		&--logo {
			width: 90px;
			height: 90px;
		}
	}

	&--link {
		text-decoration: none;
		color: #000;
		font-family: $font-heading;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 14px;

		&--logo {
			width: 100%;
			height: 100%;

			img {
				width: 100%;
				height: 100%;
			}
		}

		&:hover {
			text-decoration: none;
			color: rgba(0, 0, 0, 0.5);
		}
	}

	&--left {
		flex: 0 1 15%;
		border-right: 1px solid $nav-border-black;
		display: flex;
		justify-content: space-evenly;

		&-link {
			color: #000;
			font-size: 16px;

			&:hover {
				text-decoration: none;
				color: rgba(0, 0, 0, 0.5);

				.sahanabar--left--svg--fb {
					fill: #3b5999 !important;
					// color: unset !important; 
				}

				.sahanabar--left--svg--insta {
					fill: #e4405f !important;
					// color: unset !important; 
				}

				.sahanabar--left--svg--youtube {
					fill: #cd201f !important;
					// color: unset !important; 
				}
			}

			&:hover {
				.sahanabar--left--svg {
					fill: rgba(0, 0, 0, 0.5);
				}
			}
		}

		&--svg {
			fill: #000;
			width: 15px;
			height: 15px;
			transition: all 0.3s;
		}


	}

	&--right {
		flex: 0 1 15%;
		border-left: 1px solid $nav-border-black;
		display: flex;
		justify-content: space-evenly;
		position: relative;

		&-link {
			color: #000;

			&:hover {
				text-decoration: none;
				// color: rgba(255,255,255,0.5);
				color: rgba(0, 0, 0, 0.5);
			}

			&:hover svg {
				fill: rgba(0, 0, 0, 0.5);
			}
		}

		&-menu {
			opacity: 0;
			display: none;
			position: absolute;
			bottom: 0;
			right: -100%;
			width: 100%;
			transform: translateY(100%);
			border: 1px solid #000;
			transition: all 0.5s;
			animation: dissapear-to-right 0.5s;

			&-link {
				width: 100%;
				padding: 10px;
				display: inline-block;
				text-align: center;
				transition: all 0.5s;
				color: #000;
				background-color: #fff;
				text-decoration: none;

				&:not(:last-child) {
					border-bottom: 1px solid #000;
				}

				&:hover {
					background-color: #000;
					color: #fff;
					text-decoration: none;
				}
			}

			&-visible {
				display: block;
				animation: appear-from-right 0.5s;
				animation-fill-mode: forwards;
			}

			&-invisible {
				animation: disappear-to-right 0.5s;
			}
		}
	}

	&--center {
		display: flex;
		justify-content: center;
		flex: 1;
	}
}


.homepage__header {

	.sahanabar.sticky-sahana {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		transition: all 0.5s;
	}

	.sahanabar {
		// position: absolute;
		// top: 0;
		// left: 0;
		width: 100%;
		// border-bottom: 1px solid $nav-border-white;

		// &--link{
		// 	&:hover{
		// 		text-decoration: none;
		// 		color: rgba(255,255,255,0.5);
		// 	}
		// }

		// &--left{
		// 	border-right: 1px solid $nav-border-white !important;
		// 	a{
		// 		color: #fff;
		// 		&:hover{
		// 			color: rgba(255,255,255,0.5);
		// 		}
		// 	}
		// }

		// &--right{
		// 	border-left: 1px solid $nav-border-white !important;
		// 	&-link{
		// 		color: #fff;
		// 		&:hover{
		// 			color: rgba(255,255,255,0.5);
		// 		}
		// 	}
		// }
	}
}