.burger__menu{
	width: 21px;
	height: 17px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	&:before,
	&:after{
		position: absolute;
		left: 0;
		content: '';
		width: 21px;
		height: 3px;
		background-color: #000;
		border-radius: 5px;
		transition: all 0.4s;
	}

	&:before{
		top: 0;
	}

	&:after{
		bottom: 0;
	}

	&--bar{
		width: 21px;
		height: 3px;
		border-radius: 5px;
		background-color: #000;
		transition: all 0.4s;
	}

	&.open{
		.burger__menu--bar{
			transform: translateX(-21px);
			background-color: transparent;
		}

		&:before{
			top: 50%;
			// transform: rotate(45deg) translateY(-50%);
			transform: translateY(-50%) rotate(45deg);
		}

		&:after{
			bottom: 50%;
			// transform: rotate(-45deg) translateY(50%);
			transform: translateY(50%) rotate(-45deg);
		}
	}
}

// .homepage__header{
// 	.burger__menu{
// 		&--bar{
// 			background-color: #fff;
// 		}

// 		&:before,
// 		&:after{
// 			background-color: #fff;
// 		}
// 	}
// }
