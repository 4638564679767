.team__hr{
	margin: 18px 0;
}

.team__col{
	display: flex;
	justify-content: center;
}

.team__left{
	width: 100%;
	&--img{
		width: 100%;
		padding-top: 133.12%;
		@include respond(phone){
			padding-top: 100%;
		}
		position: relative;
		border-radius: 5px;
		overflow: hidden;
		img{
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
			width: 100%;
			height: 100%;
		}
	}

	&--field{
		color: $color-secondary;
		margin-bottom: 4px;
		font-weight: 600;
		font-family: $font-heading;
	}
	&--value{
		margin-bottom: 4px;
	}
	&--links{
		display: flex;
		a{
			&:not(:last-child){
				margin-right: 8px;
			}
		}
		svg{
			width: 16px;
			height: 16px;
		}
	}
	&--desc{
		margin-top: 4px;
		display: flex;
		flex-direction: column;
		align-items: center;
		@include respond(phone){
			align-items: flex-start;
		}
	}
}

.team__desc{
	display: flex;
	flex-direction: column;
	span{
		margin-bottom: 4px;
	}
	&--field{
		color: $color-secondary;
		margin-bottom: 4px;
		font-weight: 600;
		font-family: $font-heading;
	}
	&--value{
		margin-bottom: 4px;
	}
	&--links{
		display: flex;
		a{
			&:not(:last-child){
				margin-right: 8px;
			}
		}
		svg{
			width: 16px;
			height: 16px;
		}
	}

	&--description{
		text-align: justify;
		font-family: $font-body !important;
		line-height: 170%;
		p{
			font-family: $font-body !important;
			line-height: 170%;
		}

		span{
			font-family: $font-body !important;
			line-height: 170%;
		}
	}

	.trigger{

	}
}