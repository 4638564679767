.tab{
  &-list{
    display: flex;
    list-style: none;

    .active{
      a{
        background-color:#000;
      }
    }

    li{
      &:not(:last-child){
        margin-right: 10px;
        &:hover a{
          background-color: #000;
        }
      }


      a{
        display: inline-block;
        font-family: $font-heading;
        font-size: 1rem;
        padding: 10px;
        background-color: lighten(#000, 50%);
        color: #fff;
        text-decoration: none;
        transition: all 0.5s;
      }
    }
  }
}

.tab-panel{
  display: none;
  transition: all 0.5s;
}

.tab-panel.active{

  display: block;
  animation: fade-in ease 0.5s;
}
