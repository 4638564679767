.ratings{
	&__star{
		width: 20px;
		height: 20px;
        fill: $color-secondary;
        &:not(:last-child){
            margin-right: 7px;
        }
        &-grey{
            fill: #ccc;
        }
	}

    &__container{
        display: flex;
        justify-content: center;
        .ratings{
            display: flex;
        }
    }
}

*{
    margin: 0;
    padding: 0;
}
.rate {
    float: left;
    height: 46px;
}
.rate:not(:checked) > input {
    position:absolute;
    top:-9999px;
    display: none;
}
.rate:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:25px;
    color: #ccc;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: $color-secondary;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: lighten($color-secondary, 15%);
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: $color-secondary;
}
