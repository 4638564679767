.sahana__partners{
	&--image{
		position: relative;
		padding-top: 100%;
		a{
			position: absolute;
			display: inline-block;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 2;
		}
		img{
			position: absolute;
			top: 0;
			left: 0;
			object-fit: contain;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
	}
}