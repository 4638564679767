@keyframes fade-in{
	0%{
		opacity: 0;
	}

	100%{
		opacity: 1;
	}
}

@keyframes zoom-in{
	0%{
		// transform: translateZ(-50px);
		transform: scale(1);
	}

	50%{
		// transform: translateZ(-20px);
		transform: scale(1.1);
	}

	100%{
		// transform: translateZ(0);
		transform: scale(1.2);
	}
}

@keyframes background-color{
	0%{
		background-color: transparent;
	}

	100%{
		background-color: #fff;
	}
}

@keyframes appear-from-right{
	0%{
		right: -100%;
		opacity: 0;
	}

	100%{
		right: 0;
		opacity: 1;
	}
}

@keyframes disappear-to-right{
	0%{
		right: 0;
		opacity: 1;
		display: block;
	}

	100%{
		right: -100%;
		opacity: 0;
		display: none;
	}
}

// @keyframes collapse{
// 	0%{

// 	}

	
// }

.zoom-in-animation{
	animation: zoom-in 0.5s ease-in-out;
}