// Custom shop fixed child styles
.custom_image-hovered,
.custom_image-selected {
  &.custom_shop-fixedChild {
    // position: fixed;
    // top: 0;
    // left: 0;
  }
}

.pickup_selector {
  display: flex;
  align-items: center;
  input {
    // margin-right: 0.5rem;
  }
  .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 5px;
  }
}

// Hide set pickups at the beginning
#set_pickups {
  display: none;
}
