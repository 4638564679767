.sahana__artists{
	min-height: 0;
	&-grid{
		// display: grid;
		// grid-template-columns: repeat(auto-fit, minmax(249px, 1fr));
		// grid-template-columns: repeat(4, 22%);
		// grid-gap: 4%;
		@include respond(tab-portrait){
			// grid-template-columns: repeat(2, 48%);
			// grid-gap: 4%;
		}
	}

	&-single{
		margin-bottom: 36px;
		width: 100%;
		position: relative;
		overflow: hidden;
		&:hover .sahana__artists-info{
			opacity: 1;
			visibility: visible;
			transform: translateY(-50%) scale(1);
		}

		&:hover .sahana__artists-image{
			filter: blur(1px) brightness(80%);
		}

		&-container{
			// padding-top: 149.4%;
			padding-top: 133.12%;
			position: relative;
			&-image{
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				border-radius: 5px;
				overflow: hidden;
			}
		}
	}

	&-image{
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: all 0.5s;
	}

	&-info{
		font-family: 'Roboto', sans-serif;
		font-weight: 400;
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transition: all 0.5s;
		opacity: 0;
		visibility: hidden;
		color: #fff;
		transform: translateY(-50%) scale(0.5);
		padding: 0 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-family: $font-body;
		font-size: 14px;
	}

	&-name{
		margin-bottom: 10px;
		position: relative;
		&:after{
			position: absolute;
			bottom: -5px;
			left: 50%;
			transform: translateX(-50%);
			content: '';
			width: 50%;
			border-top: 2px solid #fff;
		}
	}

	&-designation{
		margin-bottom: 10px;
		text-align: center;
	}

	&-social{
		&--svg{
			width: 15px;
			height: 15px;
			fill: #fff;
			transition: all 0.5s;
		}
		a{
			color: #fff;
			text-decoration: none;
			&:hover{
				text-decoration: none;
			}
			&:not(:last-child){
				margin-right: 15px;
			}
		}
	}
}