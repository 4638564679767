.overlay{
    position: fixed;
    // position: sticky;
    top: 164px;
    right: 65px;
    // right: 0;
    // z-index: 99999;
    z-index:4;
    transition: .4s;
    opacity: 0;
    visibility: hidden;
    transition: .6s ;
    &:last-child{
        z-index: -1;
    }
    &-id{
        opacity: 0 !important;
        visibility: hidden;
      }
    @include respond(tab-middle){
        right: 20px;
    }
    &-trig{
        opacity: 1;
        visibility: visible;
    
       
    }
  

.redirect-id{
    // border: 1px solid $nav-border-black;
    background-color: #303133;
    border-radius: 0px 0px 5px 5px;
    // height: 600px;
    height: 720px;
    width: 300px;
    // height: 0px;
    // width: 0px;
    padding: 1rem;
    // position: fixed;
    // top: 110px;
    top: 0;
    right: 10%;
    // opacity: 0;
    // visibility: hidden;
    display: none;
    margin-bottom: 1rem;
    transition: .4s ;
    transform:translateY(-200%);
    overflow-y: scroll;
    // backdrop-filter: blur(5px);

   
    &-hidden{
        transform:translateY(0%);
        // opacity: 1;
        // visibility: visible;
        display: block;
          &::-webkit-scrollbar {
        width: 8px;
     &-track {
        border-radius: 10px;
        height: 5px;
    }

      &-thumb {
          background-color:	#DCDCDC;

        border-radius: 10px;
        height: 5px;
      }
    }


       
    }

    @include respond(tab-semi){
        // display: none;
    }
    .content{
        display: flex;
        flex-direction: column;
        margin-top: .3rem;
        color: #fff;
        &-details{
            margin-top: .3rem;
          display: flex;
          justify-content: space-between;  
        }
        .redirect{
            color: #000;
            text-decoration: none;
            margin-top: 1rem;
  }
  .shortcut{
      display: flex;
      flex-direction: column;
      &_li{
          list-style-type:none;
          padding: .5rem 0;
          color: #fff;
          a{
              color: #fff;
          }
      }
  }
  .selected-heading{
      display: none;
      @include respond(tab-middle){
          display: block;
      }
  }
    }


}
}
.toggle-icon{
    // font-size: 2rem;
    // transform: rotate(90deg);
    
    i{
        position: fixed;
        z-index: 999;
        top: 125px;
        right: 65px;
        background-color:#303133;
        // width: 150px;
        width: 300px;
        height: 40px;
        padding: .5rem;
        color: #fff;
        font-size: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        // display: none;
        // opacity: .5;
        @include respond(tab-middle){
         right: 20px;
         text-align: center;
        }
        span{
            font-size: 1.3rem;
            margin-left: 5px;
            font-weight: bolder;
            @include respond(tab-middle){
                font-size: 1rem;
            //   opacity: 0;
               display: none;
            }
        }
        &:hover{
            cursor: pointer;
        }
        @include respond(tab-middle){
            transition: .4s;
            width: 50px;
            &:hover{
                // width: 300px;
            }
           
        }
        &-full{
            width: 300px;
        }
    }

}

  