*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

$font-heading: "Poppins", sans-serif;
// $font-body: 'Playfair Display', serif;
// $font-body: 'PT Serif', serif;
$font-body: "Poppins", sans-serif;

$font-color: #303133;

//Color Variables
// $nav-border: #51413c;
$nav-border-white: rgba(#fff, 0.2);
$nav-border-black: rgba(#000, 0.1);
$color-primary-dark: #8c1f2f;
$color-primary-darker: #55131f;
$color-primary-light: #b32d46;
// $color-secondary: #bc906b;
$color-secondary: #b32d46;
$footer-top: #1b1d1f;
$footer-bottom: #141618;
$color-scroll: #303133;

html {
  scroll-behavior: smooth;
}

body {
  background-color: #fff;
  font-family: $font-body;
  font-size: 0.875rem;
  @include respond(tab-landscape) {
    font-size: 87.5%; //14px
  }

  @include respond(tab-portrait) {
    font-size: 75%; //12px
  }

  @include respond(phone) {
    // font-size: 62.5%; //10px
  }
}

section {
  padding-top: 36px;
  min-height: 65vh;
  @include respond(tab-portrait) {
    padding-top: 5px;
  }
}

section.sahana__artists {
  min-height: 0;
  padding-top: 0;
}

section.top__sellers {
  min-height: 0;
  padding-top: 36px;
  position: relative;

  // background-color: $color-secondary;
  // -webkit-mask-image: url(/frontend/img/SVG/guitar-shape1.svg);
  // -webkit-mask-size: cover;
  // mask-image: url(/frontend/img/SVG/guitar-shape1.svg);
  // mask-size: cover;
  // background-image: url(/frontend/img/SVG/guitar-shape1.svg);
  // background-size: cover;
  // background-position: bottom;
  // background-repeat: no-repeat;
  // > img{
  // 	position: absolute;
  // 	bottom: 0;
  // 	left: 0;
  // 	transform: translateY(-50%);
  // 	width: 100%;
  // }
}

section.sahana__partners {
  min-height: 0;
  padding-top: 0;
}

.homepage__grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, max-content);
}

// Utility Classes
.mb-md {
  margin-bottom: 36px;
}
.mb-xs {
  margin-bottom: 15px;
}
.mt-xs {
  margin-top: 15px;
}
.text-center {
  text-align: center;
}
.bold-text {
  font-weight: 500;
}
.mb-zero {
  margin-bottom: 0;
}
.pd-top-mobile {
  @include respond(tab-portrait) {
    padding-top: 30px;
  }
}

.accordion-panel {
  display: none;
}

.flex-25 {
  flex: 0 0 25% !important;
  max-width: 25% !important;
}

.svg {
  transition: all 0.5s;
  &__facebook {
    &:hover {
      fill: #3b5999;
    }
  }
  &__insta {
    &:hover {
      fill: #e4405f;
    }
  }
  &__youtube {
    &:hover {
      fill: #cd201f;
    }
  }
}

.svg-test {
  width: 0;
  height: 0;
}

.img-test {
  // clip-path: url(/frontend/path.svg);
}
.mor{
  // margin-right: 15rem;  
  @include respond(tab-middle){
    margin-right: 0;
  }
//  &-right{
//    margin-right: 15rem;
//    transition: .6s;
//    @include respond(tab-middle){
//     margin-right: 0;
//   }
//  }
}
.pl-20{
  @include respond(tab-middle){
  padding-left:40px;
}
}
