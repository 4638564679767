.product{
	// display: grid;
	// grid-template-columns: repeat(4, 22%);
	// grid-gap: 4%;

	&__list{
		width: 100%;
		margin-bottom: 15px;

		&-item{
			padding-top:133.12%;
			position: relative;
			overflow: hidden;
			border-radius: 5px;
			transition: all 0.5s;
			img{	
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: cover;
			}
			&-link{
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				display: inline-block;
				z-index: 2;
			}
			&-description{
				position: absolute;
				display: flex;
				flex-direction: column;
				padding: 10px 0;
				width: 100%;
				bottom: 0;
				left: 0;
				color: #fff;
				// background-color: $color-secondary;
				text-align: center;
				transform: translateY(100%);
				transition: all 0.5s;
				backdrop-filter: blur(5px) brightness(70%);
			}

			&:hover{
				.product__list-item-description{
					transform: translateY(0);
				}
				// box-shadow: 2px 2px 5px #000;
			}
		}

		&-hovered{

		}
	}
	@include respond(tab-portrait){
		grid-template-columns: repeat(2, 48%);
		grid-gap: 4%;
	}
}