.guitar__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  &--tabs {
    .nav-tabs {
      border-bottom: 1px solid $color-secondary;
    }
    .nav-link.active {
      background-color: #fff;
      color: $color-secondary;
      border-color: $color-secondary;
      border-bottom: none;
      &:hover {
        border-top: 1px solid $color-secondary;
        border-left: 1px solid $color-secondary;
        border-right: 1px solid $color-secondary;
      }
    }

    .nav-link:hover {
      border-color: $color-secondary;
      border-top: none;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
    }

    .tab-content {
      font-family: $font-body;
      line-height: 170%;
      text-align: justify;
      p,
      span,
      a,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: unset !important;
        background: unset !important;
        background-color: unset !important;
        font-size: 1rem !important;
        font-family: $font-body !important;
        width: unset !important;
        line-height: unset !important;
        text-align: unset;
      }

      .tab-pane {
        &--iframe {
          width: 100%;
          padding-top: 56.25%;
          position: relative;
          iframe {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }

  &-nav-item {
    font-family: $font-heading;
    color: #212529;
    &:hover {
      color: $color-secondary;
    }
  }

  &--number {
    width: 100%;
    height: 100%;
    text-align: center;
    border: 1px solid $color-secondary;
    border-radius: 2px;
  }

  &--qty {
    width: 45px;
    height: 45px;
    margin-right: 40px;
    margin-left: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family: $font-heading;
    &--dec,
    &--inc {
      position: absolute;
      top: 50%;
      cursor: pointer;
    }

    &--dec {
      left: -20px;
      transform: translateY(-50%) rotate(-180deg);
    }

    &--inc {
      right: -20px;
      transform: translateY(-50%);
    }
  }

  &--buttons {
    display: flex;
    justify-content: flex-start;
  }

  &--description {
  }

  &--list {
    font-size: 14px;
    list-style: none;
    background-image: url(/frontend/img/SVG/pick_small.svg);
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 14px;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &--price {
    font-family: $font-body;
    font-size: 1rem;
    font-weight: 600;
    .top__sellers-price {
      &:after {
        content: "-";
        display: inline-block;
        padding: 0 0.325em;
      }
    }
  }

  &--ratings {
    margin: 6px 0;
  }

  &-left {
    grid-column: 1 / 2;
    // @include respond(tab-portrait){
    // 	grid-column: 1 / -1;
    // }
    &-box {
      width: 100%;
      //   @include respond(phone-landscape) {
      //     width: 90%;
      //   }
      &-img {
        padding-top: 133.12%;
        position: relative;
      }
    }
  }

  &-right {
    grid-column: 2 / -1;
    display: flex;
    flex-direction: column;
    align-items: start;
    // @include respond(tab-portrait){
    // 	grid-column: 1 / -1;
    // }
  }

  &-buttons {
    display: flex;
    width: 100%;
    margin-top: 20px;
    @include respond(phone-landscape) {
      justify-content: center;
    }
    &-1 {
      background-color: yellow;
      padding: 5px;
      // flex: 1 1 auto;
      padding: 18px 40px;
      border-radius: 4px;
      border: 1px solid #777;
      font-family: $font-heading;
      background-color: transparent;
    }

    &-2 {
      background-color: pink;
      margin-left: 10px;
      padding: 5px;
      flex: 1 1 auto;
    }
  }

  &-carouselContainer {
    position: relative;
    width: 100%;
    height: 34rem;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &-carouselButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 5rem;
    height: 5rem;
    background-color: rgba(#fff, 0.5);
    &-left {
      left: 0;
      background-image: url(/frontend/sahana-images/left.png);
      background-repeat: no-repeat;
      background-position: center;
    }

    &-right {
      right: 0;
      background-image: url(/frontend/sahana-images/right.png);
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

#photo-viewer {
  position: relative;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

// #photo-viewer.is-loading::after{
// 	content: url(sahana-images/loading.gif);
// 	position: absolute;
// 	top: 0;
// 	right: 0;
// }

#thumbnails {
  //   margin: 30px 0;
  margin: 30px 0 45px 0;
  min-width: 0;
  .owl-item {
    // max-width: 100% !important;
    // width: 80px !important;
    // height: 80px !important;
  }
  .thumb {
    display: block;
    overflow: hidden;
    padding-top: 100%;
    position: relative;
    margin-bottom: 30px;
  }
  .item {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .owl-nav {
    position: relative;
    button {
      position: absolute;
      &.owl-next {
        top: 0;
        right: 0;
      }
      &.owl-prev {
        top: 0;
        right: 30px;
      }
    }
  }
}

.related__carousel {
  .guitar__grid-buttons {
    justify-content: center;
    margin-top: 8px;
    .cart-button,
    input[type="submit"] {
      padding: 5px 20px;
    }
  }
  &--item {
    padding-top: 133.12%;
    position: relative;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
    }
    &-description {
      position: absolute;
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      width: 100%;
      bottom: 0;
      left: 0;
      color: #fff;
      // background-color: $color-secondary;
      text-align: center;
      transform: translateY(100%);
      transition: all 0.5s;
      backdrop-filter: blur(5px) brightness(70%);
    }
    &:hover {
      .related__carousel--item-description {
        transform: translateY(0);
      }
      // box-shadow: 2px 2px 5px #000;
    }
  }

  &--link {
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

#related__carousel {
  margin: 30px 0;
  .owl-carousel {
    position: relative;
  }
  .owl-nav {
    position: absolute;
    // display: block;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    button {
      position: absolute;
      top: 0;
      transform: translateY(-50%);
      display: inline-block;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: center;
      span {
        display: none;
      }
      &:focus {
        outline: none;
      }

      &:hover {
        background-color: unset;
      }
    }
  }

  .owl-prev {
    left: -30px;
    transform: rotate(-180deg);
    background-image: url(/frontend/img/SVG/pick-left.svg);
  }

  .owl-next {
    right: -30px;
    background-image: url(/frontend/img/SVG/pick.svg);
  }
}

.features__table {
  margin-top: 1rem;
  tr:nth-child(odd) {
    background-color: rgba($color-secondary, 0.1);
  }
  th {
    width: 25%;
  }

  td,
  th {
    border-top: none;
    border-bottom: none;
  }

  &--th {
    font-family: $font-heading;
  }
}

.test__svg {
  stroke-width: 0.5;
  stroke: green;
  stroke-dasharray: 10 10;
  &:hover {
    stroke-dasharray: 20 20;
  }
}
