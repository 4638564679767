.star-outline{
	font-size: 20px;
}

.horizontal-ruler{
	width: 100%;
	height: 2px;
	// background-color: yellow;
}

label{
	position: relative;
	.asterik-required{
		position: absolute;
		right: -10px;
		top: 0;
		color: #b33a3a;
	}
}
