%positionAbsoluteFull {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.custom_shop-row {
  &:hover > div:not(:hover) img {
    filter: blur(2px);
    transform: scale(0.9);
  }
  > div:hover img {
    filter: unset;
    transform: scale(1);
  }
  // &:hover .col:not(:hover)
}

.shop {
  &_card {
    padding-top: 133.12%;
    position: relative;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 5px;
    transition: all 0.4s;
    // &:after {
    //   // position: absolute;
    //   // bottom: 0;
    //   // left: 0;
    //   // width: 100%;
    //   // background-color: #000;
    //   // background-color: $color-primary-dark;
    //   // background-color: $color-primary-light;
    //   // background-color: #fff;
    //   // content: "";
    //   // height: 2px;
    // }
    &:hover {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 24px 24px;
    }
    &:hover .shop_card-body-link {
      background-position: 100%;
      border: 2px solid $color-primary-light;
      border-bottom: 2px solid transparent;
      // border-bottom: 3px solid transparent;
      text-decoration: none;
    }
    &:hover .shop_card-body-link span {
      background-position: 100%;
    }
    &-link {
      @extend %positionAbsoluteFull;
      z-index: 1;
      text-decoration: none;
    }
    img {
      @extend %positionAbsoluteFull;
      border-radius: 5px;
      object-fit: cover;
      transition: all 0.4s;
    }
    &-body {
      position: absolute;
      // transform: rotate(-90deg) translateX(100%);
      // transform-origin: bottom right;
      // right: 0;
      // bottom: 1rem;
      left: 50%;
      transform: translate(-50%);
      bottom: 0;
      z-index: 2;
      background-color: rgba(255, 255, 255, 1);
      // background-color: transparent;
      // backdrop-filter: blur(5px);
      padding: 0;
      &-link {
        display: inline-block;
        transition: all 0.4s;
        background-image: linear-gradient(
          to right,
          $color-primary-light 0%,
          $color-primary-light 50%,
          transparent 50%
        );
        // background-image: linear-gradient(
        //   to right,
        //   $color-primary-dark 0%,
        //   $color-primary-dark 50%,
        //   transparent 50%
        // );
        background-size: 200%;
        border: 2px solid rgb(0, 0, 0);
        border: 2px solid $color-primary-light;
        // border: 2px solid #fff;
        // border-bottom: 3px solid #000;
        // font-size: 1rem;
        font-weight: 500;
        color: #000;
        color: #fff;
        text-decoration: none;
        padding: 0.3rem 0.3rem;
        span {
          color: transparent;
          background-image: linear-gradient(
            to right,
            #fff 0%,
            #fff 50%,
            #000 50%
          );
          background-clip: text;
          background-size: 200%;
        }
      }
    }
  }
}
