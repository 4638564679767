.heading-secondary{
	font-family: $font-heading !important;
	font-weight: 900;
	text-transform: uppercase;
	font-size: 20px;
	color: $font-color;
	display: inline-block;
	position: relative;
	&:after{
		position: absolute;
	    content: '';
	    bottom: -5px;
	    left: 50%;
	    transform: translateX(-50%);
	    width: 50%;
	    border-top: 2px solid $font-color;
	}
}

.heading-4{
	font-family: $font-heading;
	display: inline-block;
	position: relative;
	font-size: 0.875rem;
	font-weight: 700;
}

.heading-1{
	font-family: $font-heading;
	margin-bottom: 0;
}

.heading-2{
	font-size: 16px;
}

.heading-3{
    font-family: $font-heading;
    margin-bottom: 0;
    font-size: 1.5rem;
    position: relative;
    display: inline-block;
}

.heading-underline{
    &:after{
        content: '';
        position: absolute;
        bottom: -2px;
        width: 90%;
        left: 0;
        height: 2px;
        background-color: #000;
    }
}
