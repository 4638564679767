.sahanabar__mobile{
	// position: absolute;
	// top: 0;
	// left: 0;
	width: 100%;
	display: none;
	z-index: 3;
	padding: 20px;
	// @include clearfix;
	transition: background-color 0.5s;
	backdrop-filter: blur(2px);
	&.sticky-mobile{
		position: fixed;
		background-color: #fff;
	}

	&--mega-link:link,
	&--mega-link:visited{
		color: #fff;
	    background-color: #000;
	    display: inline-block;
	    width: 100%;
	    height: 100%;
	    padding: 5px 0 5px 30px;
	    transition: all 0.5s;
	    text-decoration: none;
	    font-family: $font-heading;
	}

	&--dropdown{
		&-link:link,
		&-link:visited{
			color: #000;
			padding: 5px 15px;
			display: inline-block;
			width: 100%;
			text-decoration: none;
			font-family: $font-heading;
		}
	}

	&-dropdown{
		display: none;
		background-color: #fff;
	}

	&--mega-ul{
		display: none;
	}

	@include respond(tab-portrait){
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
	}

	&--bgWhite{
		background-color: #fff;
		// animation: background-color 0.5s;
		// transition: background 0.5s;
	}

	&--leftContainer{
		width: 60px;
		height: 60px;
		&--logo{
			width: 100%;
			height: 100%;
			overflow: hidden;
			img{
				width: 100%;
				height: 100%;
			}
		}
	}

	&--left{
		float: left;
		display: flex;
		align-items: center;
		&-link{
			&:not(:last-child){
				margin-right: 10px;
			}
		}

		&--svg{
			width: 12px;
			height: 12px;
		}
	}

	&--right{
		float: right;
		display: flex;
		align-items: center;
		position:  relative;
		&--logo:link,
		&--logo:visited{
			color: #000;
			margin-right: 10px;
		}
		a{
			// width: 21px;
			// height: 17px;
			// position: relative;
			// display: inline-block;

			img{
				position: absolute;
				&:first-child{
					top: 0;
					left: 0;
				}

				&:nth-child(2){
					top: 50%;
					transform: translateY(-50%);
					left: 0;
				}

				&:last-child{
					bottom: 0;
					left: 0;
				}
			}
		}

		&-menu{
			visibility: hidden;
			opacity: 0;
			position: absolute;
			width: 150px;
			top: 50px;
			right: 0;
			display: flex;
			flex-direction: column;
			text-align: center;
			background-color: #fff;
			z-index: 4;
			transition: all 0.5s;
			border-radius: 2px;
			&-visible{
				opacity: 1;
				visibility: visible;
			}
			&-link:link,
			&-link:visited{
				padding: 5px 0;
				color: #000;
				text-decoration: none;
				&:not(:last-child){
					border-bottom: 1px solid #000;
				}
			}
		}
	}

	&-list{

	}

	&-link{
		&:visited,
		&:link{
			color: #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			padding: 5px 15px;
			text-decoration: none;
			font-family: $font-heading;
		}
	}

	&-panel{
		position: absolute;
		z-index: 3;
		top: 100%;
		left: 0;
		width: 100%;
		// height: 500px;
		background-color: yellow;
		display: none;
		background-color: #000;
	}
}

.sahanabar__mobile--bgWhite{
	.sahanabar__mobile{
		&--right--logo{
			color: #000;
		}
	}

	.burger__menu{
		&:before,
		&:after{
			background-color: #000;
		}

		&-bar{
			background-color: #000;
		}
	}	
}

.homepage__header{
	// .sahanabar__mobile{
	// 	&--right--logo{
	// 		color: #fff;
	// 	}
	// }

	.sahanabar__mobile--bgWhite{
		.sahanabar__mobile{
			&--right--logo{
				color: #000;
			}
		}
	}
}